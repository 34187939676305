import React from 'react'
import styled from 'styled-components'
import { Card } from '@pancakeswap-libs/uikit'

export const BodyWrapper = styled(Card)`
  position: relative;
  max-width: 525px;
  width: 100%;
  z-index: 5;
  box-shadow: 0 -26px 87px rgb(66 103 178 / 0%), 0 0px 70px rgb(66 103 178 / 25%);
  height: 100%;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>
    {children}
    </BodyWrapper>
}
