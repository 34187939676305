import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import {BrowserView, MobileView} from 'react-device-detect';

const StyledNav = styled.div`
  margin-bottom: 40px;
`

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  const TranslateString = useI18n()
  return (
    <StyledNav>
      {/* <BrowserView> */}
      <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
        <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
          {TranslateString(1142, 'Swap')}
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
          {TranslateString(262, 'Liquidity')}
        </ButtonMenuItem>
        {/* <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://farms.easydex.io"
        >
          Farm
        </ButtonMenuItem> */}
        {/* <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://farms.easydex.io/syrup"
        >
          Pool
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://farms.easydex.io/info"
        >
          Info
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://snapshot.org/#/easydex.eth/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Vote
        </ButtonMenuItem> */}
      </ButtonMenu>
      {/* </BrowserView> */}
      {/* <MobileView>
      <ButtonMenu activeIndex={activeIndex} scale="xs" variant="subtle">
        <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
          {TranslateString(1142, 'Swap')}
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
          {TranslateString(262, 'Liquidity')}
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://farms.easydex.io"
        >
          Farm
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://farms.easydex.io/syrup"
        >
          Pool
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://farms.easydex.io/info"
        >
          Info
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://snapshot.org/#/easydex.eth/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Vote
        </ButtonMenuItem>
      </ButtonMenu>
      </MobileView> */}

    </StyledNav>
  )
}

export default Nav
