import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

* {
  font: 16px/1.75 "Quicksand", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  z-index: 2;
}
  body {
    background: linear-gradient(to bottom right, #f0f2f5, #f0f2f5);
    ::-webkit-scrollbar {
      display: none;
    }
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
